<template>
	<div v-if="authed">
    <splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
        <pane min-size="25" size="45" ref="toppane">
<!--		<div style="max-height: 100%" class="overflow-y-auto"> -->
        <v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
			:options.sync="options" :server-items-length="totalItems"
			:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
            :loading="loading" loading-text="加载中..." show-select single-select>
            <template v-slot:top>
				<div class="d-flex flex-wrap pt-3 pb-1 pl-1" style="gap:16px" ref="tabletopbar">
                <v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
                    <v-btn>未答卷</v-btn>
                    <v-btn>未开单</v-btn>
                    <v-btn>待筛选</v-btn>
                    <v-btn>待确认</v-btn>
                    <v-btn>已确认</v-btn>
                    <v-btn>全部</v-btn>
                </v-btn-toggle>
				<v-text-field placeholder="姓名" v-model="pname" dense clearable hide-details style="flex: 0 1 auto;max-width:150px;"
					append-icon="search" @click:append="fetchData" @keyup.enter.native="fetchData"/>
				<v-select label="术前" persistent-placeholder placeholder="全部" clearable v-model="pre" :items="['华兆','华三','外院']" dense hide-details
					style="flex: 0 1 auto;max-width:120px;" @change="fetchData"/>
				<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
					transition="scale-transition" offset-y min-width="290px">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="pdate1" label="预约评估日期起" readonly v-bind="attrs"
							v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
					</template>
					<v-date-picker v-model="pdate1" @input="menu1 = false" no-title scrollable></v-date-picker>
				</v-menu>
				<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="10"
					transition="scale-transition" offset-y min-width="290px">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field v-model="pdate2" label="预约评估日期止" readonly v-bind="attrs"
							v-on="on" dense hide-details clearable style="flex: 0 1 auto;max-width:150px;"></v-text-field>
					</template>
					<v-date-picker v-model="pdate2" @input="menu2 = false" no-title scrollable></v-date-picker>
				</v-menu>
				</div>
                <v-divider/>
            </template>
            <template v-slot:item.bmi="{ item }">
                <span :style="{color:getBMI(item) >30 ? 'red':'black'}">{{getBMI(item)}}</span>
            </template>
            <template v-slot:item.progress.payment="{ item }">
                {{formatTime(item.progress.payment)}}
            </template>
            <template v-slot:item.progress.qa="{ item }">
                {{formatTime(item.progress.qa)}}
            </template>
            <template v-slot:item.progress.examlistIssued="{ item }">
                {{formatTime(item.progress.examlistIssued)}}
            </template>
            <template v-slot:item.progress.eereviews="{ item }">
                {{formatTime(item.progress.eereviews)}}
            </template>
            <template v-slot:item.progress.eeaccepted="{ item }">
                {{formatTime(item.progress.eeaccepted)}}
            </template>
            <template v-slot:item.dob="{ item }">
                {{item.pii.dob}}
            </template>
			<template v-slot:item.insurance="{ item }">
				<span :class="item.payment.insurance ? 'teal--text text--accent-4 font-weight-bold' : 'black--text'">{{getInsurance(item)}}</span>
			</template>
            <template v-slot:item.pii.idcard="{ item }">
                {{getMaskedIdcard(item)}}
            </template>
            <template v-slot:item.gender="{ item }">
                {{getGender(item)}}
            </template>
            <template v-slot:item.age="{ item }">
                {{getAge(item)}}
            </template>
            <template v-slot:item.opts="{ item }">
                {{getOptsStr(item)}}
            </template>
        </v-data-table>
<!--        </div> -->
        </pane>
        <pane min-size="20" size="55" v-if="selected.length > 0">
            <div style="max-height: 100%" class="overflow-y-auto">
                <v-tabs v-model="tab" style="position:sticky;top:0;z-index:99;">
                    <v-tab>健康问卷</v-tab>
                    <v-tab :disabled="!canEdit">病历记录</v-tab>
					<!--<v-tab :disabled="!selected[0].payment.insurance">保险面评</v-tab>-->
					<v-tab :disabled="true">保险面评</v-tab>
                    <v-tab>检查项清单</v-tab>
                    <v-tab>上传结果</v-tab>
                    <v-tab>筛选结果</v-tab>
                    <v-tab>结果确认</v-tab>
                    <v-tab :disabled="!selected[0].payment.insurance">保险术前结果</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
                    <v-tab-item>
						<div v-if="selected[0].QA && selected[0].QA.questionnaire">
							<v-list-item v-for="(item, i) in selected[0].QA.questionnaire" :key="item.category" dense>
								<v-list-item-content>
									<v-list-item-title>{{item.category}}</v-list-item-title>
									<v-checkbox v-for="q in item.questions" :key="q.Q" :label="q.Q" v-model="q.A" :true-value="'1'" :false-value="'0'"
										:readonly="lockedQA" class="ml-6 mt-0" hide-details>
									</v-checkbox>
								</v-list-item-content>
							</v-list-item>
							<div class="mt-4">
								<v-btn v-if="canEdit && !selected[0].examlist" outlined color="primary" class="ml-4" @click.stop="lockedQA=!lockedQA">编辑</v-btn>
								<v-btn v-if="canEdit && !selected[0].examlist" :disabled="lockedQA" color="primary" class="ml-2" :loading="savingDialog" @click.stop="updateQA">保存</v-btn>
							</div>
						</div>
						<div v-else>
							<v-btn v-if="canEdit" color="primary" class="ml-4 mt-4" @click.stop="loadDefaultQA" :disabled="!!selected[0].examlist">创建问卷</v-btn>
						</div>
                    </v-tab-item>
                    <v-tab-item :disabled="!canEdit">
						<!--
                        <div style="padding:12px 0;margin:0 40px;">
							<v-text-field v-for="hi in historyItems" :key="hi.label" :label="hi.label" v-model="hi.value"/>
							<v-btn rounded color="primary" width="120" class="mt-0 mb-4" @click="submitHistory"
								:loading="savingHistory" :disabled="savingHistory">提交</v-btn>
						</div>
						-->
						<div>
							<v-btn class="ml-8 mt-6 mb-4" rounded width="120" color="primary" :disabled="!canDiag" @click.stop="addRecord">添加病历</v-btn>
							<diagnose-history :items="MedicalHistory" @edit="editRecord" @remove="removeRecord"/>
						</div>
                    </v-tab-item>
					<!--<v-tab-item :disabled="!selected[0].payment.insurance">-->
					<v-tab-item :disabled="true">
						<v-form style="padding:12px 32px" :disabled="!selected[0].payment.insurance || !!selected[0].diagConclusion" ref="diagform">
							<v-radio-group v-model="bcm4" row :class="{'d-none':onlyW}">
								<v-radio label="3包清肠药" :value="false"></v-radio>
								<v-radio label="4包清肠药" :value="true" class="ml-6"></v-radio>
							</v-radio-group>
							<v-radio-group v-model="diagConclusion" row :rules="acRules">
								<v-radio label="通过" value="通过"></v-radio>
								<v-radio label="未通过" value="未通过" class="ml-16"></v-radio>
							</v-radio-group>
							<v-btn rounded color="primary" width="120" class="mt-0 mb-4" @click.stop="saveDiagConclusion"
								:loading="loading" :disabled="!canDiag||loading||!selected[0].payment.insurance||!!selected[0].diagConclusion">提交结论</v-btn>
						</v-form>
					</v-tab-item>
                    <v-tab-item>
						<v-container>
						<v-row justify="start">
							<v-col md="4">
								<v-form style="padding:12px 0;margin:0 8px;" :readonly="lockedExam1">
									<v-treeview selectable open-all dense :items="examlistGrouped1" v-model="examItems1t">
										<template v-slot:label="{item}">
											{{item.id}}<v-icon v-if="getDetail(item.id).length > 0" class="material-icons-outlined ml-1" @click.stop="showDetail(item.id)">list_alt</v-icon>
										</template>
									</v-treeview>
									<v-btn rounded color="primary" width="150" class="ml-6 mt-4 mb-4" @click="submitExam1"
										:loading="savingExam1" :disabled="savingExam1||lockedExam1">提交</v-btn>
								</v-form>
							</v-col>
						</v-row>
						</v-container>
                    </v-tab-item>
                    <v-tab-item class="pl-4 pt-4">
						<v-btn large color="primary" @click.stop="loadHuazhao" :disabled="!canLoadHuazhao">查询华兆报告</v-btn>
						<v-btn large color="primary" @click.stop="localUpload" :disabled="!canLoadHuazhao" outlined class="ml-2">本地上传报告</v-btn>
                    </v-tab-item>
                    <v-tab-item>
                        <v-toolbar dense tile>
                            <span class="mr-1">待分配标签</span>
                            <v-chip v-for="t in remainTags" :key="t" small class="mx-1">{{t}}</v-chip>
                            <v-spacer/>
                            <v-btn small color="primary" @click="saveReview" :disabled="savingReview||lockedReview">保存</v-btn>
                        </v-toolbar>
                        <v-container fluid>
                            <v-row>
                                <v-card v-for="(r,idx) in eeresults" :key="idx" class="ma-2" max-width="300">
                                    <v-img :src="r" lazy-src="../assets/logo.png" width="300" height="300" @click="showImage(idx)">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height ma-0" align="center" justify="center">
                                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                    <v-card-actions>
                                        <v-select label="标签" multiple small-chips deletable-chips hide-details
                                            placeholder="请选取标签"
                                            v-model="eereviews[idx].reviews"
                                            @change="onChangeReview($event,idx)"
                                            :items="avlTags">
                                        </v-select>
                                    </v-card-actions>
                                </v-card>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item>
						<v-container>
						<v-row justify="start">
							<v-col md="3">
								<v-form style="padding:12px 32px" :readonly="lockedConclusion1" ref="ercform">
									<v-radio-group v-model="conclusion1" column :rules="acRules">
										<v-radio label="检查结果单已收讫" value="accepted"></v-radio>
									</v-radio-group>
									<v-btn rounded color="primary" width="120" class="mt-0 mb-4" @click.stop="saveConclusion1"
										:loading="savingConclusion1" :disabled="savingConclusion1||lockedConclusion1||!acceptEeresult">提交</v-btn>
									<v-btn v-if="canCancelAccept" rounded color="primary" width="120" class="mt-0 mb-4" outlined @click.stop="cancelAccept"
										:loading="savingConclusion1" :disabled="savingConclusion1||!conclusion1">取消收讫</v-btn>
								</v-form>
							</v-col>
						</v-row>
						</v-container>
                    </v-tab-item>
                    <v-tab-item :disabled="!selected[0].payment.insurance">
						<v-form style="padding:12px 32px;width:600px;" :disabled="!selected[0].payment.insurance || !!selected[0].diagConclusion" ref="diagform2">
							<v-radio-group v-model="diagConclusion" dense row :rules="acRules" class="mt-0">
								<v-radio label="通过" value="通过"></v-radio>
								<v-radio label="未通过" value="未通过" class="ml-16"></v-radio>
							</v-radio-group>
							<v-btn rounded color="primary" width="120" class="mt-2 mb-4" @click.stop="saveDiagConclusion2"
								:loading="loading" :disabled="loading||!selected[0].payment.insurance||!!selected[0].diagConclusion">提交结论</v-btn>
						</v-form>
                    </v-tab-item>
                </v-tabs-items>
            </div>
			<v-snackbar v-model="snackbarSuccess" color="success">保存成功</v-snackbar>
			<v-snackbar v-model="snackbarError" color="error">保存失败</v-snackbar>
			<v-snackbar v-model="snackbarHuazhao" color="orange darken-2">未查询到图片</v-snackbar>
        </pane>
    </splitpanes>
	<v-dialog v-model="detaildialog" width="360">
		<v-card>
			<v-card-title>{{curdetail}}</v-card-title>
			<v-card-text>
				<v-list dense>
					<v-list-item v-for="(item,idx) in getDetail(curdetail)" :key="idx">
						{{idx+1}}、{{item}}
					</v-list-item>
				</v-list>
			</v-card-text>
		</v-card>
	</v-dialog>
	<v-dialog v-model="savingDialog" width="360" persistent>
		<v-card :loading="savingExam1||savingReview||savingConclusion1||savingPic" class="pb-1">
			<template slot="progress">
				<v-progress-linear v-if="savingPic" height="20" striped :value="picTotal ? picUploading/picTotal*100 : 100">
					<span style="font-size:14px">{{picUploading + ' / ' + picTotal}}</span>
				</v-progress-linear>
				<v-progress-linear v-else height="6" indeterminate/>
			</template>
			<v-card-title>提示</v-card-title>
			<v-card-text class="text-center text-h6">
				<span v-if="!savingFailed" class="black--text">正在提交，请稍候...</span>
				<span v-else class="red--text darken-2">提交失败，请重新尝试提交</span>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" :disabled="savingExam1||savingReview||savingConclusion1||savingPic" @click.stop="savingDialog=false">确定</v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<image-uploader v-model="iu" :clouddir="clouddir" :files="imagefiles" @saved="onPicSaved"/>
	<viewer @inited="inited" ref="viewer" :images="eeresults" style="display:none">
		<template slot-scope="scope">
        	<img v-for="src in scope.images" :src="src" :key="src">
	    </template>
	</viewer>
	<diagnose v-model="diagnose" :patient="selected[0]" :mrindex="mrindex" @change="fetchMr"/>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, formatDate, calculateAge, nowOffsetHours, md5p} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
	import 'splitpanes/dist/splitpanes.css'
	import DiagnoseHistory from '../components/DiagnoseHistory.vue';
	import Diagnose from '../components/Diagnose.vue';
	import ImageUploader from '@/components/ImageUploader.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:120},
                    {text:'支付方', value:'insurance', width:150},
                    {text:'性别', value:'gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'出生日期', value:'dob', width:110},
                    {text:'身高', value:'QA.baseinfo.bodyheight', width:80},
                    {text:'体重', value:'QA.baseinfo.bodyweight', width:80},
                    {text:'BMI', value:'bmi', width:80},
                    {text:'证件号', value:'pii.idcard', width:150},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'健康顾问', value:'consultantName', width:100},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'术前', value:'opts.PRE', width:90},
                    {text:'面评结论', value:'diagConclusion', width:100},
                    {text:'付款时间', value:'progress.payment', width:180},
                    {text:'答卷时间', value:'progress.qa', width:180},
                    {text:'开单时间', value:'progress.examlistIssued', width:180},
                    {text:'筛选时间', value:'progress.eereviews', width:180},
                    {text:'结果确认时间',value:'progress.eeaccepted', width:180},
                    {text:'付款备注', value:'payment.comment', width:200},
                    {text:'订单号', value:'_id', width:100},
                    {text:'订单类型', value:'orderType', width:120},
                    {text:'诊所', value:'region', width:100},
                ],
                items: [],
                loading: false,
                selected: [],
				historyItems: [],
				historylist: [],	//元表
                examlist: [],		//元表
				savingHistory: false,
				savingExam1: false,
				savingExam2: false,
				lockedExam1: false,
				lockedExam2: false,
				exam1Saved: false,
				exam2Saved: false,
				snackbarSuccess: false,
                snackbarError: false,
				snackbarHuazhao: false,
                eeresults: [],
				eereviews: [],
                savingReview: false,
				lockedReview: false,
                fullImageSrc: [],
                conclusion1: null,
                savingConclusion1: false,
                lockedConclusion1: false,
                conclusion2: null,
                savingConclusion2: false,
                lockedConclusion2: false,
				filter: 0,
				detaildialog: false,
				curdetail: '',
				examlistGrouped1: [],
				examlistGrouped2: [],
				examItems1t: [],
				examItems2t: [],
				options: {sortBy:['pid'], sortDesc:[true]},
				totalItems: 0,
				pname: '',
				pdate1: '',
				menu1: false,
				pdate2: '',
				menu2: false,
				pre: '',
				acRules: [v => !!v || '此项为必填'],
				savingDialog: false,
				savingFailed: false,
				myFiles: [],
				savingPic: false,
				picUploading: 0,
				picTotal: 0,
				acceptEeresult: false,
				canCancelAccept: false,
				canLoadHuazhao: false,
				canEdit: false,
				lockedQA: true,
				defaultQA: [],
				diagConclusion: '',
				canDiag: false,
				diagnose: false,
				tableheight: undefined,
				mrindex: -1,
				iu: false,
				imagefiles: [],
				MedicalHistory: [],
				tab: null,
				bcm4: false,
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege(['术前检查单','消化内科','麻醉科','健康顾问']);
			this.canEdit = this.$hasPrivilege(['术前检查单','消化内科','麻醉科']);
			this.acceptEeresult = this.$hasPrivilege('收讫检查单');
			this.canCancelAccept = this.$hasPrivilege('取消收讫');
			this.canLoadHuazhao = this.$hasPrivilege(['取华兆报告','健康顾问']);
			this.canDiag = this.$hasPrivilege('消化内科');
			if (!this.authed) return;
            this.formatTime = formatTime;
            const db = this.$tcbapp.database();
            db.collection('workset').doc('current').get().then(res => {
				this.defaultQA = res.data[0].questionnaire.map(x => ({...x,questions:x.questions.map(y => ({'Q':y,'A':'0'}))}));
                this.historylist = res.data[0].historylist;
				this.examlist = res.data[0].examlist;
				this.examlistGrouped1 = this.makeTree(res.data[0].examlist);
				this.examlistGrouped2 = this.makeTree(res.data[0].examlist);
            }).catch(console.error);
            this.fetchData();
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 59;
				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
				setTimeout(() => {
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async loadHuazhao() {
				const id = this.selected[0].pii.idcard;
				const url = `https://www.advahealth.com.cn:3443/customize/MyService.asmx/GetHuaSanReport?sfzh=${id}`;
				try {
					const res = await this.$axios.get(url);
					const data = res.data.replace('<?xml version="1.0" encoding="utf-8"?>', '').replace('<string xmlns="http://tempuri.org/">', '').replace('</string>', '');
					const jdata = JSON.parse(data);
					if (jdata.data.length > 0) {
						const images = jdata.data.map(x => x.FilePath);
						this.imagefiles = images;
						this.iu = true;
					} else {
						this.snackbarHuazhao = true;
					}
				} catch(err) {
					this.snackbarHuazhao = true;
				}
			},
			localUpload() {
				this.imagefiles = [];
				this.iu = true;
			},
			getInsurance(item) {
				return item.payment.insurance && item.payment.insurer ? `${item.payment.insurer} - ${item.payment.insurance}` : '自费';
			},
			getBMI(item) {
				if (!item.QA || !item.QA.baseinfo || !item.QA.baseinfo.bodyheight) return '-';
				const h = item.QA.baseinfo.bodyheight * 0.01;
				const bmi = item.QA.baseinfo.bodyweight / (h * h);
				return bmi.toFixed(1);
			},
			getRemainTag(item) {
				let el1 = item.examlist || [];
				let el = el1.filter(x=>x.value).map(x=>x.label);
				let eld = Array.from(new Set(el));
				const rs = item.eereviews || [];
				let r = rs.reduce((acc,cur) => cur.reviews ? acc.concat(cur.reviews) : acc, []);
				let rd = Array.from(new Set(r));
				let remain = eld.filter(v => rd.indexOf(v) === -1);
				return remain;
			},
			async fetchMr() {
				this.MedicalHistory = [];
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp2order')
						.where({
							pid: this.selected[0].pid,
							MedicalHistory: _.exists(true),
						})
						.field({MedicalHistory:true})
						.get();
					const r = res.data.find(x => x._id === this.selected[0]._id);
					if (r) {
						this.selected[0].MedicalHistory = r.MedicalHistory;
					}
					this.MedicalHistory = res.data
						.flatMap(x => {
							x.MedicalHistory.forEach((y, index) => {
								if (x._id === this.selected[0]._id) {
									y.id = x._id;
									y.mrindex = index;
								}
							});
							return x.MedicalHistory;
						})
						.sort((a, b) => a.time - b.time);
				} catch(err) {
					console.error(err);
				}
			},
            async fetchData() {
                const db = this.$tcbapp.database();
                const _ = db.command;
				let filter = [{"payment.actual":_.exists(true)}];
				if (this.$hasPrivilege('术前检查单')) {
					//admin也有此权限
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId: u.consultantId});
				}
				const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
				const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
				if (t1 && t2) {
					filter.push({apntEvalDate:_.gt(t1).lt(t2)});
				} else if (this.pdate1) {
					filter.push({apntEvalDate:_.gt(t1)});
				} else if (this.pdate2) {
					filter.push({apntEvalDate:_.lt(t2)});
				}
				if (this.pname) {
					filter.push({'pii.username':this.pname});
				}
				if (this.pre) {
					filter.push({'opts.PRE':this.pre});
				}
				filter.push({hide:_.neq(true)});
				filter.push({orderSource:_.neq('定向')});
				const filters = [
					[{'QA.questionnaire':_.exists(false)}, {eeaccepted:_.exists(false)}],
					_.and([{'QA':_.exists(true)}, {examlist:_.exists(false)}]),
					_.and([{examlist:_.exists(true)}, {eeaccepted:_.exists(false)}]),
					_.and([{examlist:_.exists(true)}, {eeaccepted:_.exists(false)}]),
					[{eeaccepted:_.exists(true)}, {'progress.operative':_.exists(false)}],
					[]
				];
				const f = filter.concat(filters[this.filter]);
				const postfilters = [
					v => true,
					v => true,
					v => this.getRemainTag(v).length > 0,
					v => this.getRemainTag(v).length === 0,
					v => true,
					v => true,
				];
                this.loading = true;
                try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
                    this.items = res.data.filter(postfilters[this.filter]);
                } catch(err) {
                    console.error(err);
                }
				this.loading = false;
			},
			inited(viewer) {
				this.$viewer = viewer;
			},
			showImage(idx) {
				if (this.eeresults.length === 0) return;
				this.$viewer.show();
				this.$viewer.view(idx);
			},
			makeTree(examlist) {
				return examlist.reduce((acc,cur) => {
					const d = { id:cur.title, name:cur.title, detail:cur.detail, disabled:cur.deprecated, deprecated:cur.deprecated };
					let e = acc.find(x => x.id === cur.category);
					if (e) {
						e.children.push(d);
					} else {
						acc.push({ id:cur.category, name:cur.category, children:[d], disabled:false });
					}
					return acc;
				}, []);
			},
			setTreeDisabled(tree, disabled) {
				for(let x of tree) {
					x.disabled = disabled || x.deprecated
				}
			},
			showDetail(c) {
				this.curdetail = c;
				this.detaildialog = true;
			},
			getDetail(c) {
				if (!c) return [];
				const d = this.examlist.find(x=> x.title === c);
				return (d && d.detail) || [];
			},
            getConclusion(c) {
                if(c === 'accepted') {
                    return '通过'
                } else if (c === 'rejected') {
                    return '不通过'
                } else {
                    return ''
                }
            },
			getMaskedIdcard(item) {
				//return item.pii.idcard.replace(/^(.{3})(?:\d+)(.{3})$/,  "\$1****\$2");
				return item.pii.idcard;
			},
            getGender(item) {
                return item.pii.gender;
            },
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
            },
			async loadDefaultQA() {
				if (!this.selected[0].QA) this.selected[0].QA = {};
				this.selected[0].QA.questionnaire = Object.assign({}, this.defaultQA);
				this.$set(this.selected[0], 'QA.questionnaire', this.defaultQA);
				this.lockedQA = false;
			},
			async updateQA() {
				this.savingDialog = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'updateQuestionnaire',
							data: {
								id: this.selected[0]._id,
								questionnaire: this.selected[0].QA.questionnaire
							}
						}
					});
//					await new Promise(r => setTimeout(r, 3000));	//测试延迟
                    this.fetchData();
					this.snackbarSuccess = true;
				} catch(err) {
					console.error(err);
					this.snackbarError = true;
				} finally {
					this.savingDialog = false;
					this.lockedQA = true;
				}
			},
            submitHistory() {
				this.savingHistory = true;
	            const db = this.$tcbapp.database();
				const id = this.selected[0]._id;
				db.collection('wp2order').doc(id).update({
					history: this.historyItems,
					historyUpdateTime: db.serverDate({offset:nowOffsetHours * 3600 * 1000})
				}).then(res=>{
					this.snackbarSuccess = true;
				}).catch(err=>{
					this.snackbarError = true;
				}).finally(()=>{
					this.savingHistory = false;
				})
            },
			async submitExam1() {
				this.savingExam1 = true;
				this.savingFailed = false;
				this.savingDialog = true;
				const id = this.selected[0]._id;
				const examlist = this.examlist.map(x=>({label:x.title, value:this.examItems1t.includes(x.title)}));
//				setTimeout(() => {this.savingDialog = false; this.savingExam1 = false;}, 10000)			//测试对话框
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveExamList',
							data: {
								id,
								examlist
							}
						}
					});
					this.savingDialog = false;
					this.lockedExam1 = true;
					this.exam1Saved = true;
                    this.snackbarSuccess = true;
					this.setTreeDisabled(this.examlistGrouped1, true)
                    this.fetchData();
				} catch(err) {
					this.savingFailed = true;
					this.snackbarError = true;
					console.error(err);
				} finally {
					this.savingExam1 = false;
				}
            },
            onChangeReview(e,idx) {
                if (e.indexOf('rejected') !== -1) {
                    this.$set(this.eereviews[idx], 'reviews', ['rejected']);
                    this.$set(this.eereviews[idx], 'state', 'rejected');
                } else if (e.length > 0) {
                    this.$set(this.eereviews[idx], 'state', 'accepted');
                } else {
                    this.$set(this.eereviews[idx], 'state', 'pending');
                }
            },
            async saveReview() {
                this.savingReview = true;
				this.savingFailed = false;
				this.savingDialog = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveReviews',
							data: {
								id,
								eereviews: this.eereviews
							}
						}
					});
					this.savingDialog = false;
					this.snackbarSuccess = true;
				} catch(err) {
					console.error(err);
					this.savingFailed = true;
					this.snackbarError = true;
				}
				this.savingReview = false;
            },
            async saveConclusion1() {
				if (!this.$refs.ercform.validate()) return;
                this.savingConclusion1 = true;
				this.savingFailed = false;
				this.savingDialog = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveEeaccepted',
							data: {
								id,
								eeaccepted: this.conclusion1
							}
						}
					});
                    await this.fetchData();
					this.lockedConclusion1 = true;
					this.savingDialog = false;
					this.snackbarSuccess = true;
				} catch(err) {
					this.savingFailed = true;
					console.error(err);
					this.snackbarError = true;
				}
				this.savingConclusion1 = false;
            },
			async cancelAccept() {
				const res = await this.$dialog.confirm({
					text: '确定要取消收讫？',
					title: '提示'
				});
				if (!res) return;
                this.savingConclusion1 = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'cancelEeaccepted',
							data: {
								id,
							}
						}
					});
                    await this.fetchData();
				} catch(err) {
					console.error(err);
				}
				this.savingConclusion1 = false;
			},
			async saveDiagConclusion() {
				this.$refs.diagform.validate();
				if (!this.$refs.diagform.validate()) return;
                this.loading = true;
				this.savingFailed = false;
				this.savingDialog = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveDiagConclusion',
							data: {
								id,
								bcm4: this.bcm4, //Bowel cleansing medicines
								diagConclusion: this.diagConclusion
							}
						}
					});
					if (this.selected[0].payment.insurance === '自付') {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname: 'preAuthorize',
								data: {
									id
								}
							}
						});
					}
                    await this.fetchData();
					this.selected = [this.items.find(x => x._id === id)];
					this.lockedConclusion1 = true;
					this.savingDialog = false;
					this.snackbarSuccess = true;
				} catch(err) {
					this.savingFailed = true;
					console.error(err);
					this.snackbarError = true;
				}
				this.loading = false;
			},
			async saveDiagConclusion2() {
				this.$refs.diagform2.validate();
				if (!this.$refs.diagform2.validate()) return;
                this.loading = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'saveDiagConclusion2',
							data: {
								id,
								diagConclusion: this.diagConclusion
							}
						}
					});
					if (this.selected[0].payment.insurance === '自付') {
						await this.$tcbapp.callFunction({name:"wp2mp",
							data:{
								funcname: 'preAuthorize',
								data: {
									id
								}
							}
						});
					}
                    await this.fetchData();
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
			async onPicSaved(r) {
				if (this.selected.length === 0 || r.length === 0) return;
				const files = r.filter(x => !this.eeresults.find(y => y === x));	//消除重复
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'uploadEeresult',
							data: {
								id,
								files
							}
						}
					});
					const db = this.$tcbapp.database();
					const res = await db.collection('wp2order').doc(id).field({eeresults:true}).get();
					if (res.data.length > 0) {
						this.eeresults = res.data[0].eeresults;
						this.selected[0].eeresults = this.eeresults;
						this.eereviews = this.selected[0].eereviews || [];
						while (this.eereviews.length < this.eeresults.length) {
							this.eereviews.push({state:'pending', reviews:[]});
						}
						this.selected[0].eereviews = this.eereviews;
					}
					this.$dialog.message.success('上传成功');
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('上传失败');
				}
				this.loading = false;
			},
			addRecord() {
				this.mrindex = -1;
				this.diagnose = true;
			},
			editRecord(idx) {
				this.mrindex = idx;
				this.diagnose = true;
			},
			async removeRecord(index) {
				const res = await this.$dialog.warning({
					text: '确定要删除此项记录？',
					title: '提示'
				});
				if (!res) return;
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					const res = await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname:'removeMedicalRecord',
							data: {
								id,
								index,
							}
						}
					});
					if (!res.result.ret) {
						this.$dialog.error({title:'删除失败', text:res.result.msg});
						return;
					}
					this.fetchMr();
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
					this.loading = false;
				}
			},
        },
		watch: {
			pdate1(newv, oldv) {
				if (newv && this.pdate2) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate2 = newv;
					}
				}
				this.fetchData();
			},
			pdate2(newv, oldv) {
				if (newv && this.pdate1) {
					const t1 = this.pdate1 ? new Date(this.pdate1 + 'T' + '00:00:00+08:00') : null;
					const t2 = this.pdate2 ? new Date(this.pdate2 + 'T' + '23:59:59+08:00') : null;
					if (t1 > t2) {
						this.pdate1 = newv;
					}
				}
				this.fetchData();
			},
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				this.$nextTick(()=>{
					if (this.$refs.pond) {
						this.$refs.pond.removeFiles();
					}
				})
                if (newitem.length === 0) return;
				if (newitem[0].history) {
					this.historyItems = Object.assign(newitem[0].history);
				} else {
					this.historyItems = this.historylist.map(x => ({label:x, value:''}));
				}
				if (newitem[0].examlist) {
					this.examItems1t = newitem[0].examlist.filter(x=>x.value).map(x=>x.label);
					this.setTreeDisabled(this.examlistGrouped1, true)
					this.lockedExam1 = true;
					this.exam1Saved = true;
				} else {
					this.examItems1t = [];
					this.setTreeDisabled(this.examlistGrouped1, false)
					this.lockedExam1 = !this.$hasPrivilege('消化内科');
					this.exam1Saved = false;
                }
				const s1 = 'cloud://huasan-test-id.6875-huasan-test-id-1300427821';
				const s2 = 'https://6875-huasan-test-id-1300427821.tcb.qcloud.la';
				const s3 = 'cloud://huasan-6gaadaha7ceb62a9.6875-huasan-6gaadaha7ceb62a9-1300427821';
				const s4 = 'https://6875-huasan-6gaadaha7ceb62a9-1300427821.tcb.qcloud.la';
				this.eeresults = (newitem[0].eeresults || []).map(x => x.replace(s1, s2).replace(s3, s4));
				this.eereviews = newitem[0].eereviews || [];
				while (this.eereviews.length < this.eeresults.length) {
					this.eereviews.push({state:'pending', reviews:[]});
				}
				this.lockedReview = !this.$hasPrivilege(['术前检查单','消化内科','麻醉科']);
                this.conclusion1 = newitem[0].eeaccepted;
                this.lockedConclusion1 = !!this.conclusion1 || !this.$hasPrivilege(['术前检查单','消化内科']);
				this.getRemainTag(newitem[0]);
				this.diagConclusion = newitem[0].diagConclusion;
				this.bcm4 = !!newitem[0].evalConclusion && !!newitem[0].evalConclusion.gastroPrompt3;
				this.fetchMr();
            }
        },
        computed: {
			onlyW() {
				if (this.selected.length === 0) return;
				return this.selected[0].opts.GIE === '胃镜';
			},
			clouddir() {
				if (this.selected.length === 0) return "";
				return `eeresults/${this.selected[0]._id}`;
			},
            avlTags() {
				const examItems = this.examlist.map((x,i) => {
					const label = x.title;
					const value = this.examItems1t.includes(x.title) || this.examItems2t.includes(x.title);
					return {label, value};
				});
                return examItems.filter(x=>x.value).map(x=>x.label).concat([{divider:true},{text:'其他',value:'others'},{text:'无效资料',value:'rejected'}]);
            },
            remainTags() {
                if (!this.eereviews) return [];
				const examItems = this.examlist.map((x,i) => {
					const label = x.title;
					const value = this.examItems1t.includes(x.title) || this.examItems2t.includes(x.title);
					return {label, value};
				});
                let a = examItems.filter(x=>x.value).map(x=>x.label);
                this.eereviews.forEach(x => {
                    if (x.reviews) {
                        x.reviews.forEach(y => {
                            const i = a.indexOf(y);
                            if (i !== -1) a.splice(i, 1);
                        });
                    }
                });
                return a;
            }
        },
        components: { Splitpanes, Pane, DiagnoseHistory, Diagnose, ImageUploader }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
.filepond--item {
    width: calc(25% - 0.5em);
}
</style>